import React, {useCallback} from 'react';
import {Col, Input, Row} from 'antd';
import b_ from 'b_';
import './styles.scss';
import EditableField from '../../../Components/EditableField';

const b = b_.lock('ConfigItem');

function ConfigItem({item, handleSave, isLoading}) {

  const handleConfigItemSave = useCallback((value) => handleSave(item.name, value), [item.name, fetch]);

  return (
    <Row className={b()}>
      <Col flex="600px" className={b('title')}>
        {item.name}
      </Col>
      <Col flex="auto">
        <EditableField
          handleSave={handleConfigItemSave}
          withTitle
          title={item.name}
          initialValue={item.value}
          iconClassName={b('icon')}
          isLoading={isLoading}
          changeBlock={(props) => (
            <Input.TextArea
              {...props}
              onChange={(e) => props.onChange(e.target.value)}
            />
          )}
        >
          {item.value}
        </EditableField>
      </Col>
    </Row>
  );
}

export default ConfigItem;
