import b_ from 'b_';
import React, { useEffect, useState } from 'react';
import './styles.scss';
import TokenService from '../../Services/TokenService';
import AmazonOutlined from '@ant-design/icons/lib/icons/AmazonOutlined';
import { Card, Button } from 'antd';
import {getRedirectUrl} from "../../Utils/envDependent";

const b = b_.lock('SignInForm');
const {
  REACT_APP_AUTH_CLIENT_ID: CLIENT_ID,
  REACT_APP_AUTH_URL: AUTH_URL,
  REACT_APP_AUTH_RESPONSE_TYPE: RESPONSE_TYPE,
  REACT_APP_AUTH_SCOPE: SCOPE,
} = process.env;

function SignInForm() {

  const url = `${AUTH_URL}/authorize?client_id=${CLIENT_ID}&response_type=${RESPONSE_TYPE}&scope=${SCOPE}&redirect_uri=${getRedirectUrl()}`;

  const [sha, setSha] = useState(null);
  useEffect(() => {
    TokenService.generateAndSaveSha256().then((data) => {
      setSha(data);
    });
  }, []);

  return (
    <Card className={b('card')} title="Sign In">
      <a className={b('link')} href={sha && url + `&code_challenge_method=S256&code_challenge=${sha}`}>
        <Button className={b('button')} type="primary" size="large" loading={sha === null}>
          <AmazonOutlined />
          SignIn with Amazon Cognito
        </Button>
      </a>
    </Card>
  );
}

export default SignInForm;
