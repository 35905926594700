import React, {useCallback, useEffect} from 'react';
import '../styles.scss';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getBalances} from "../../../Reducers/wallet";
import {ReloadOutlined, WalletOutlined} from "@ant-design/icons";
import useMediaQuery from '../../../Utils/useMediaQuery';
import MobMenu from './MobMenu';
import Sider from 'antd/lib/layout/Sider';
import { Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';

function Balances() {

  const dispatch = useDispatch();

  const balances = useSelector(state => state.wallet.balances.payload, shallowEqual);

  const handleClick = useCallback(() => dispatch(getBalances()), [dispatch]);

  useEffect(() => {
    dispatch(getBalances())
  }, [dispatch])

  const media1250 = useMediaQuery('(max-width: 890px)')

  function transformObjToArray(obj) {
    const arr = [];
  
    for (const [key, value] of Object.entries(obj)) {
     
      const label = <span title={key} style={{cursor: 'default'}}>{value.length > 0 ? value : 'no data'}</span>;
      arr.push({ label, key });
    }
  
    return arr;
  }

  const transformedArr = balances && transformObjToArray(balances);
  console.log(transformedArr);

  return (
    <>
    {media1250 ? (
    <Sider width="63px" className="MobMenuBalances">
      
      <Menu
          mode="vertical"
          forceSubMenuRender={false}
          // defaultSelectedKeys={(path.length && [path[0].href]) || ''}
          // defaultOpenKeys={(path.length && [path[0].href]) || ''}
          // onOpenChange={() => setIcon(!icon)}
          onClick={handleClick}
          triggerSubMenuAction={"click"}
          style={{
            borderRight: 0,
          }}
        >
          <SubMenu
            key="menu"
            style={{fontSize: 15}}
            title={<WalletOutlined style={{fontSize: 20}}/>}
          >
            {balances && transformedArr.map((item, index) => (
                <Menu.Item key={index}>
                  {item.label}
                </Menu.Item>
              )
            )}
          </SubMenu>
        </Menu>
        </Sider>
        ) : (
  <div className="Balance">
    <ReloadOutlined style={{marginRight: 10}} title="Fetch" onClick={handleClick}/>
    <span
      title={"User balance | Wallet balance | Kraken USD | Kraken EUR | Kraken BTC"}>{balances && `${balances.clientBalance} | ${balances.walletBtc} | ${balances.walletUsdt} | ${balances.exchUsdBal} | ${balances.exchEurBal} | ${balances.exchBtcBal}`}</span>
  </div>
  ) }
  </>
  )
}

export default Balances;
