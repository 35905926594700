import ApiService from './ApiService';

const UsersService = {

  getUsersList({ filters, orderPath, ...params }) {
    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = filters[key].join(',');
      }
    }
    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }
    return ApiService.get('/users', updatedParams);
  },

  updateUsers(data) {
    const updatedData = Object.keys(data).reduce((newData, key) => {
      if (![null, undefined].includes(data[key])) {
        newData[key] = data[key];
      }
      return newData;
    }, {});

    return ApiService.post('/users/update', updatedData);
  },
  getUpdatePageData() {
    return ApiService.get(`/users/update`);
  },
  addUser(data) {
    return ApiService.post('/user', data);
  },


};

export default UsersService
