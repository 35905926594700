import {createAction, createReducer} from '@reduxjs/toolkit';
import frc from 'front-end-common';
import CardsService from "../Services/CardsService";

const {getDefaultHandler, getDefaultState} = frc.utils.reducerTools;

export const getCardsPage = createAction('cards/get', (params) => ({
  payload: CardsService.getCardsPage(params),
}))

export const addEditCard = createAction('card/add-edit', (data) => ({
  payload: CardsService.addEditCard(data),
}))

const initState = {
  cardsPage: getDefaultState(),
  addEditResult: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(getCardsPage, 'cardsPage'),
  ...getDefaultHandler(addEditCard, 'addEditResult'),
});
