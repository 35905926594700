import { createAction, createReducer } from '@reduxjs/toolkit';
import frc from 'front-end-common';
import UsersService from "../Services/UsersService";

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

export const usersPageFetch = createAction('users/page', (params) => ({
  payload: UsersService.getUsersList(params),
}))

export const updateUsers = createAction('users/update', (data) => ({
  payload: UsersService.updateUsers(data),
}))

export const getUpdatePageData = createAction('codes/get-update-page-data', () => ({
  payload: UsersService.getUpdatePageData(),
}))

export const addUser = createAction('user/add', (data) => ({
  payload: UsersService.addUser(data),
}))

const initState = {
  page: getDefaultState(),
  updateUsers: getDefaultState(),
  getUpdatePageData: getDefaultState(),
  addUser: getDefaultState()
};

export default createReducer(initState, {
  ...getDefaultHandler(usersPageFetch, 'page'),
  ...getDefaultHandler(updateUsers, 'updateUsers'),
  ...getDefaultHandler(getUpdatePageData, 'getUpdatePageData'),
  ...getDefaultHandler(addUser, 'addUser')
});
