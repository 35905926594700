import { useState } from 'react'
import { Button, Menu } from 'antd'
import {BuildOutlined, AppstoreOutlined, MenuOutlined, ColumnHeightOutlined} from "@ant-design/icons";
import './styles.scss'
import Sider from 'antd/lib/layout/Sider';
import Balances from '../Balances';

const {SubMenu} = Menu;

const IconType = ({state}) => {
  return (
    <>
      {state ? <ColumnHeightOutlined style={{fontSize: 20}}/> : <MenuOutlined style={{fontSize: 20}}/>}
    </>
  )
}

const MobMenu = ({ items }) => {

  const [icon, setIcon] = useState(false)
  const [menuOpened, setMenuOpened] = useState(false)

  const toggleMenu = () => {
    setMenuOpened((status) => !status)
  }

  return (
    
    <Sider width="63px" className="MobMenu">
      <Menu
          mode="vertical"
          forceSubMenuRender={false}
          // defaultSelectedKeys={(path.length && [path[0].href]) || ''}
          // defaultOpenKeys={(path.length && [path[0].href]) || ''}
          onOpenChange={() => setIcon(!icon)}
          triggerSubMenuAction={"click"}
          style={{
            borderRight: 0,
          }}
        >
          <SubMenu
            key="menu"
            style={{fontSize: 15}}
            title={<IconType state={icon}/>}
          >
            {items.map((item, index) => (
                <Menu.Item key={index}>
                  {item.label}
                </Menu.Item>
              )
            )}
          </SubMenu>
        </Menu>
        </Sider>
    
  )
}
export default MobMenu
