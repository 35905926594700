import React, {useCallback, useState, useEffect} from 'react';
import b_ from 'b_';
import {Col, Input, Row} from 'antd';
// import { debounce } from 'lodash';
import './styles.scss';
import {Button} from "antd";
import { debounce } from 'lodash';
import { useMemo } from 'react';

const b = b_.lock('UserFilters');

function UserFilters({handleSearch, className, filters, onFilter}) {
  const [curFilters, setCurFilters] = useState(filters);
  
  
  const debouncedSearch = useMemo(
    () => debounce(handleSearch, 500)
  , [])
  
  useEffect(() => {
    setCurFilters(filters)
  }, [filters])
  

  const handleChangeFilter = useCallback((value, field) => {
    const preparedValue = Array.isArray(value) ? value : value === '' ? null : [value];
    setCurFilters({...curFilters, [field]: preparedValue});
    // console.log('PREP', preparedValue)
  }, [curFilters, setCurFilters]);

  return <Row gutter={16} justify={'start'} className={`${className} ${b()}`}>
    <Col order={1} style={{width: 200}}>
      <span className={b('label')}>Login</span>
      <Input value={curFilters.email ? curFilters.email[0] : null}
             allowClear
             className={b('item')}
             onChange={e => handleChangeFilter(e.target.value, 'email')}
      />
    </Col>
    <Col order={2} style={{width: 300}}>
      <span className={b('label')}>BTC Wallet</span>
      <Input value={curFilters.btcWallet ? curFilters.btcWallet[0] : null}
             allowClear
             className={b('item')}
             onChange={e => handleChangeFilter(e.target.value, 'btcWallet')}
      />
    </Col>
    <Col order={3} style={{width: 200}}>
      <span className={b('label')}>Comment</span>
      <Input className={b('item')}
             allowClear
             value={curFilters.comment ? curFilters.comment[0] : null}
             onChange={e => handleChangeFilter(e.target.value, 'comment')}
      />
    </Col>
    <Col gutter={2} order={5}>
      <span className={b('label')} style={{marginBottom: 0, marginTop: 8}}/>
      <Button type="primary" onClick={() => onFilter(curFilters)}>Filter</Button>
    </Col>
  </Row>;
}

export default UserFilters;
